<template>
    <v-container class="noSpace specializedMachinesContainer mb-n16" fluid>
        <v-row align="center" justify="center">
            <p class="centuryGothicBold screenTitle white--text">
                MODALIDADES DE APLICACIÓN DE MAQUINARIA ESPECIALIZADA
            </p>
        </v-row>

        <v-row
            v-for="(machine, index) in machinesList"
            :key="index"
            justify="start"
            align="center"
        >
            <v-col
                cols="12" md="5" lg="5" xl="5"
            >
                <SpecializedMachine_Card :product="machine"></SpecializedMachine_Card>
            </v-col>

            <v-col
                cols="12" md="7" lg="7" xl="7"
                align-self="start"
            >
                <v-row align="start" justify="start" class="noSpace">
                    <v-col align-self="start" align="start" class="noSpace">
                        <p class="centuryGothicBold productCardTitle pt-3 text-uppercase" style="color: #e6413d">MODALIDAD {{ machine.name.toUpperCase() }}</p>

                        <p class="centuryGothic white--text mt-6">
                            <span v-for="(benefit, index) in machine.benefitsList" :key="index">• {{ benefit }}<br></span>
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SpecializedMachine_Card from "../cards/SpecializedMachine_Card";

export default {
    name: "SpecializedMachines_Section",
    components: {
        SpecializedMachine_Card,
    },
    data() {
        return {
            machinesList: [
                {
                    img: 'Cascada.png',
                    name: 'Cascada',
                    subtitle: 'Silo a gravedad',
                    pluginsList: [],
                    benefitsList: [
                        'Esta modalidad es ideal para obras que no cuenten con energía eléctrica.',
                        'Es recomendable para trabajos de albañilería tales como junteos, repellos y microconcreto en interiores y exteriores.',
                        'Para uso en morteros secos de cemento, morteros de cal, microconcretos, todos hasta 9 mm de granulometría de partícula.',
                    ],
                },
                {
                    img: 'Remolino 50.png',
                    name: 'Remolino 50',
                    subtitle: 'Silo a gravedad',
                    pluginsList: [
                        'Amasadora D-50 L/MIN'
                    ],
                    benefitsList: [
                        'Esta modalidad requiere que la obra cuente con energía eléctrica o con el apoyo de un generador eléctrico.',
                        'Es ideal para trabajos de albañilería tales como junteos, repellos y microconcreto en interiores y exteriores.',
                        'Para uso en morteros secos de cemento, morteros de cal, microconcretos, todos hasta 6 mm de granulometría de partícula.',
                        'Una D-50 suministra mortero húmedo a 8 cuadrillas de 10 albañiles cada una.',
                    ],
                },
                {
                    img: 'Ciclon.png',
                    name: 'Ciclón',
                    subtitle: 'Silo a gravedad',
                    pluginsList: [
                        'Amasadora D-50 L/MIN',
                        'Bomba P-50 L/MIN'
                    ],
                    benefitsList: [
                        'Esta modalidad requiere que la obra cuente con energía eléctrica TRIFÁSICA, o con el apoyo de un generador eléctrico que nos entregue 3 fases eléctricas.',
                        'Esta modalidad es ideal para morteros o concretos con agregado máximo de 8 mm.',
                        'La distancia en horizontal máxima a la que se puede transportar el material es de 80 m.',
                        'La distancia en vertical máxima a la que se puede transportar el material es de 30 m',
                        'Esta modalidad nos entrega un máximo de 50 litros de mezcla hidratada por minuto.',
                        'Ideal para rellenar cadenas, castillos y alveolos de tabiques extruidos.',
                    ],
                },
                {
                    img: 'Torbellino Amasador.png',
                    name: 'Torbellino Amasador',
                    subtitle: 'Silo Presurizado',
                    pluginsList: [
                        'Bloque de Elevación',
                        'Compresor',
                        'Amasadora D-30'
                    ],
                    benefitsList: [
                        'Esta modalidad requiere que la obra cuente con energía eléctrica o con el apoyo de un generador eléctrico.',
                        'Esta modalidad es ideal para trabajos de albañilería tales como junteos, repellos y microconcreto en interiores y exteriores.',
                        'Para uso en morteros secos de cemento, morteros de cal, microconcretos, todos hasta 6 mm de granulometría de partícula.',
                        'Una D-30 suministra mortero húmedo a 4 cuadrillas de 10 albañiles cada una.',
                        'La distancia estimada a la que suministra el mortero en seco un transporte\n' +
                        'neumático F-160 es de 20 metros en horizontal y 60 metros en vertical, dando un total de 80 metros.',
                    ],
                },
                {
                    img: 'Torbelliino Lanzado.png',
                    name: 'Torbellino Lanzado',
                    subtitle: 'Silo Presurizado',
                    pluginsList: [
                        'Bloque de Elevación',
                        'Compresor',
                        'Lanzadora Duomix'
                    ],
                    benefitsList: [
                        'Esta modalidad requiere que la obra cuente con energía eléctrica o con el apoyo de un generador eléctrico.',
                        'Esta modalidad es ideal para trabajos de repellos de cemento, yeso y acabados de hasta 4 mm de granulometría de partícula en interiores y exteriores.',
                        'Brinda homogeneidad en la mezcla del mortero con el agua con la instalación de una lanzadora de mortero tipo Duo Mix que recibe el material en seco proveniente del silo hasta una distancia sugerida de 50 metros máximo en horizontal.',
                        'Permite un control del agua gracias al caudalímetro que posee.',
                        'Avance rápido en la obra brindando un rendimiento de 100 m2/día.',
                        'La distancia estimada a la que suministra el mortero en seco un transporte neumático F-160 es de 20 metros en horizontal y 60 metros en vertical, dando un total de 80 metros.',
                    ],
                },
            ]
        }
    }
}
</script>

<style scoped>
.specializedMachinesContainer {
    background-color: #3D3533;
    padding: 50px 150px !important;
}

.screenTitle {
    text-align: center;
    font-size: 2em;
    padding: 0 200px !important;
}

.productCardTitle {
    font-size: 20px;
}

@media (max-width: 960px) {
    .specializedMachinesContainer {
        background-color: #3D3533;
        padding: 50px 25px !important;
    }

    .screenTitle {
        font-size: 1.25em;
        padding: 0 !important;
    }
}
</style>