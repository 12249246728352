<template>
    <v-hover v-slot="{ hover }">
        <v-card
            :elevation="hover ? 8 : 2"
            class="my-2 machineCard"
        >
            <!-- CARD TITLE -->
            <p class="centuryGothicBold productCardTitle pt-3">{{ product.name }}</p>

            <!-- PRODUCT IMAGE -->
            <img
                :src="require('./../../assets/machines/' + product.img)"
                alt=""
                class="productCardImage"
            >

            <!-- GO TO PRODUCT BUTTON -->
            <v-row class="noSpace pt-1 px-4" justify="end">
                <v-col class="noSpace">
                    <v-container fluid class="detailsContainer">
                        <p class="centuryGothic detailsText">
                            <span v-for="(item, index) in product.detailsList" :key="index">
                                - {{ item }} <br>
                            </span>
                        </p>
                    </v-container>

                    <v-row class="noSpace mb-2">
                        <v-col class="noSpace">
                            <p class="centuryGothicBold text-uppercase noSpace" style="text-align: center; color: #3D3533;">Familias Compatibles</p>

                            <v-row class="noSpace" justify="center" align="center">
                                <v-col cols="3" v-if="product.rellena === true">
                                    <v-img
                                        src="./../../assets/icons/Micro_Concretos.svg"
                                    ></v-img>
                                </v-col>
                                <v-col cols="3" v-if="product.levanta === true">
                                    <v-img
                                        src="./../../assets/icons/Junteos.svg"
                                    ></v-img>
                                </v-col>
                                <v-col cols="3" v-if="product.recubre === true">
                                    <v-img
                                        src="./../../assets/icons/Estucos.svg"
                                    ></v-img>
                                </v-col>
                                <v-col cols="3" v-if="product.coloca === true">
                                    <v-img
                                        src="./../../assets/icons/Adhesivos_y_Nivelantes.svg"
                                    ></v-img>
                                </v-col>
                            </v-row>

                            <p class="centuryGothic noSpace" style="text-align: center; color: #3D3533; font-size: 0.725em;">Consulta a tu asesor para conocer compatibilidad de productos por familia.</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "Machine_Card",
    props: [
        'product'
    ],
}
</script>

<style scoped>
.machineCard {
    height: auto;
}

.detailsContainer {
    height: 35vh;
}

.productCardTitle {
    text-align: center !important;
    font-size: 20px;
}

.productCardImage {
    width: 100%;
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
}

.detailsText {
    font-size: 0.85em;
}

@media (max-width: 960px) {
    .machineCard {
        height: auto;
    }

    .detailsContainer {
        height: auto;
    }
}
</style>