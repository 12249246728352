<template>
    <v-container fluid class="noSpace py-16">
        <v-row class="noSpace" align="center" justify="center">
            <v-col>
                <v-row align="center" justify="center">
                    <p class="centuryGothicBold screenTitle">MAQUINARIAS ESPECIALIZADAS</p>
                </v-row>

                <v-row align="center" justify="center">
                    <p class="centuryGothic screenTitle" style="color: #e6413d;">AMASADORAS</p>
                </v-row>

                <v-row justify="start" align="center" class="machinesRow">
                    <v-col
                        v-for="(machine, index) in mixersList"
                        cols="12" md="3" lg="3" xl="3"
                        :key="index"
                        align-self="start"
                    >
                        <Machine_Card
                            :product="machine"
                        ></Machine_Card>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center">
                    <p class="centuryGothic screenTitle mt-10" style="color: #e6413d;">LANZADORAS</p>
                </v-row>

                <v-row justify="start" align="center" class="machinesRow">
                    <v-col
                        v-for="(machine, index) in launchersList"
                        cols="12" md="3" lg="3" xl="3"
                        :key="index"
                        align-self="start"
                    >
                        <Machine_Card
                            :product="machine"
                        ></Machine_Card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Machine_Card from "../cards/Machine_Card";

export default {
    name: "Machines_Section",
    components: {
        Machine_Card,
    },
    data() {
        return {
            mixersList: [
                {
                    img: "D20.png",
                    name: "D20",
                    detailsList: [
                        "Entrega de 20 litros de mezcla hidratada por minuto.",
                        "220 V, 2 fases",
                        "Trabaja Sola",
                        "Partícula máxima de 6mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
                {
                    img: "D30.png",
                    name: "D30",
                    detailsList: [
                        "Entrega de 30 litros de mezcla hidratada por minuto.",
                        "220 V, 3 fases",
                        "Puede trabajar sola o conectada a un sistema de transporte neumático.",
                        "Partícula máxima de 6mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
                {
                    img: "D50.png",
                    name: "D50",
                    detailsList: [
                        "Entrega de 50 litros de mezcla hidratada por minuto.",
                        "220 V, 2 fases",
                        "Se debe conectar a un silo forzosamente.",
                        "Partícula máxima de 8mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
                {
                    img: "D100.png",
                    name: "D100",
                    detailsList: [
                        "Entrega de 100 litros de mezcla hidratada por minuto.",
                        "220 V, 3 fases",
                        "Se debe conectar a un silo forzosamente.",
                        "Partícula máxima de 8mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
            ],
            launchersList: [
                {
                    img: "Monomix.png",
                    name: "Monomix",
                    detailsList: [
                        "Entrega de 20 litros de mezcla hidratada por minuto lanzada al muro.",
                        "220 V, 2 fases.",
                        "Alcance de hasta 20 metros de distancia vertical u horizontal.",
                        "Trabaja sola.",
                        "Partícula máxima de 4mm",
                    ],
                    recubre: true,
                    levanta: false,
                    rellena: false,
                    coloca: false,
                },
                {
                    img: "Duomix.png",
                    name: "Duomix",
                    detailsList: [
                        "Entrega de 22 litros de mezcla hidratada por minuto lanzada al muro.",
                        "220 V, 3 fases.",
                        "Alcance de hasta 20 metros de distancia vertical u horizontal.",
                        "Puede trabajar sola o conectada a un sistema de transporte neumático.",
                        "Partícula máxima de 4mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
                {
                    img: "M330.png",
                    name: "M330",
                    detailsList: [
                        "Entrega de 24 litros de mezcla hidratada por minuto lanzada al muro.",
                        "220 V, 2 fases.",
                        "Alcance de hasta 20 metros de distancia vertical u horizontal.",
                        "Partícula máxima de 4mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                },
                {
                    img: "MP25.png",
                    name: "PUTZMEISTER MP25",
                    detailsList: [
                        "Entrega de 25 litros de mezcla hidratada por minuto lanzada al muro.",
                        "220 V, 3 fases.",
                        "Alcance de hasta 15 metros de distancia vertical u horizontal.",
                        "Puede trabajar sola o conectada a un sistema de transporte neumático.",
                        "Partícula máxima de 4mm",
                    ],
                    recubre: true,
                    levanta: true,
                    rellena: true,
                    coloca: false,
                }
            ]
        }
    }
}
</script>

<style scoped>
.machinesRow {
    padding: 0 50px !important;
}

@media (max-width: 960px) {
    .machinesRow {
        padding: 0 25px !important;
    }
}
</style>