<template>
    <v-container class="pa-0" fluid>
        <!-- PAGE JUMBOTRON -->
        <ImageJumbotron_Section
            img-path="SCMB.jpg"
            text="REINVENTADO LA FORMA DE CONSTRUIR"
        ></ImageJumbotron_Section>

        <!-- ABOUT SCMB -->
        <v-row align="center" class="pa-16 mb-6 noSpace aboutSCMBContainer" justify="start">
            <v-col align="start">
                <p class="centuryGothicBold screenTitle mt-6 white--text">¿QUÉ ES EL SISTEMA CONSTRUCTIVO MEZCLA BRAVA?</p>

                <p class="centuryGothic" style="text-align: center;">
                    El <b>Sistema Constructivo Mezcla Brava</b> es un sistema moderno y eficiente, que integra todas las
                    áreas en las que nuestro equipo de especialistas brinda apoyo técnico y logístico durante todos
                    los pasos de la construcción, con lo que potenciamos los beneficios de utilizar Mezcla Brava,
                    dando como resultado principal la optimización de tus costos.
                </p>

                <p class="centuryGothic" style="text-align: center;">
                    La <b>Asistencia Técnica</b> de nuestros especialistas se complementa a la perfección con nuestra amplia
                    línea de productos elaborados con materiales de la más alta calidad, los cuales están diseñados
                    y elaborados para adaptarse y satisfacer las necesidades que se presenten en cada etapa de tu obra.
                </p>
            </v-col>
        </v-row>

        <!-- SCMB CARDS -->
        <v-row align="center" class="px-16 noSpace" justify="center">
            <v-col class="pa-0">
                <!-- CARDS TITLE -->
                <v-row align="center" justify="center">
                    <p class="centuryGothicBold screenTitle mt-6" style="color: #e6413d !important;">PILARES DEL SISTEMA CONSTRUCTIVO</p>
                </v-row>

                <!-- CARDS ROW -->
                <v-row align="start" class="cardsRow" justify="center">
                    <v-col v-for="(card, index) in cardList" :key="index" align="center" class="cardColumn mb-12" sm="12" md="4">
                        <!-- CARD IMAGE -->
                        <v-img
                            :src="require(`../../assets/mb/${card.img}`)"
                            aspect-ratio="1"
                            class="cardImage"
                        ></v-img>

                        <!-- CARD LABEL -->
                        <p class="centuryGothicBold mt-6 cardLabel">
                            {{ card.label }}
                        </p>

                        <!-- CARD DESCRIPTION -->
                        <v-container class="pa-8 cardTextContainer">
                            <p class="centuryGothic white--text cardText">
                                {{ card.description }}
                            </p>
                        </v-container>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- SILOS SECTION -->
        <Silos_Section></Silos_Section>

        <!-- SILOS BENEFITS SECTION -->
        <SilosBenefits_Section></SilosBenefits_Section>

        <!-- MACHINES SECTION -->
        <Machines_Section></Machines_Section>

        <!-- SPECIALIZED MACHINES SECTION -->
        <SpecializedMachines_Section></SpecializedMachines_Section>

        <!-- SCMB VIDEO -->
        <v-container class="pt-8 pb-16 mb-n16 mt-16" fluid style="background-color: #EBEAEA;">
            <v-col align="center">
                <p class="centuryGothicBold screenTitle mb-8" style="color: #e6413d;">
                    SISTEMA CONSTRUCTIVO MEZCLA BRAVA
                </p>

                <v-row align="center" justify="center">
                    <iframe
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        class="youTubePlayer"
                        frameborder="0"
                        src="https://www.youtube.com/embed/k5xov2hwZ00"
                        title="YouTube video player"
                    ></iframe>
                </v-row>
            </v-col>
        </v-container>
    </v-container>
</template>

<script>
import ImageJumbotron_Section from "../../components/sections/ImageJumbotron_Section";
import Silos_Section from "../../components/sections/Silos_Section";
import SilosBenefits_Section from "../../components/sections/SilosBenefits_Section";
import Machines_Section from "../../components/sections/Machines_Section";
import SpecializedMachines_Section from "../../components/sections/SpecializedMachines_Section";

export default {
    name: "SCMB",
    components: {
        ImageJumbotron_Section,
        Silos_Section,
        SilosBenefits_Section,
        Machines_Section,
        SpecializedMachines_Section,
    },
    data() {
        return {
            cardList: [
                {
                    img: 'asesoria_tecnica_en_construccion.png',
                    label: 'ASESORÍA #DePrincipioAFin',
                    description: 'Nuestro Equipo Técnico capacita a tus colaboradores y personal de la obra, dando asesoría y soporte técnico para lograr el mejor desempeño de los productos y equipos a tu disposición.',
                },
                {
                    img: 'silos_amasadoras_y_lanzadoras_para_construccion.png',
                    label: 'SILOS, AMASADORAS Y LANZADORAS',
                    description: 'La mezcla donde la necesites y cuando la necesites. \n' +
                        'La tecnología trabaja de nuestro lado para reducir tiempos de acarreo y preparación de material, así como una aplicación que garantiza la homogeneidad de la mezcla que incrementa el rendimiento del producto, reduce desperdicios y el esfuerzo físico de tu personal, dando como resultado un mayor avance por jornada.',
                },
                {
                    img: 'soluciones_logisticas_en_construccion.png',
                    label: 'SOLUCIONES LOGÍSTICAS',
                    description: 'Ya sea una obra de vivienda vertical u horizontal; o que utilices el producto en saco o a granel, tenemos la capacidad operativa para satisfacer tu demanda de material y maquinaria.',
                },
            ]
        }
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | Sistema Constructivo',
            meta: [
                { vmid: 'description', name: 'description', content: 'El Sistema Constructivo Mezcla Brava es un sistema moderno y eficiente, en el cual se abarcan todas las áreas en las que el equipo de especialistas brinda apoyo técnico y logístico en tu obra, con el que potenciamos los beneficios de utilizar Mezcla Brava, dando como resultado principal la optimización de tus costos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.cardImage {
    width: 100%;
}

.cardLabel {
    color: #e6413d !important;
    font-size: 1.8em;
}

.cardTextContainer {
    border-bottom-right-radius: 180px;
    border-bottom-left-radius: 180px;
    background-color: #3D3533;
    min-height: 65vh;
    width: 100%;
}

.cardText {
    font-size: 1em;
}

.youTubePlayer {
    width: 66%;
    height: 60vh;
}

.aboutSCMBContainer {
    padding: 20px 150px 50px 150px !important;
    background-color: #EE403D;
    color: white;
}

.cardsRow {
    padding: 0 50px !important;
}

.cardColumn {
    padding: 0 25px !important;
}

@media (max-width: 960px) {
    .aboutSCMBContainer {
        padding: 0 25px !important;
    }

    .cardsRow {
        padding: 0 !important;
    }

    .youTubePlayer {
        width: 95%;
        height: 40vh;
    }
}
</style>