<template>
    <v-container
        fluid
        class="pa-0"
    >
        <v-img
            height="100vh"
            class="mt-n6"
            :src="require(`../../assets/mb/${imgPath}`)"
            gradient="to bottom, rgba(39, 37, 40 ,.01), rgba(39, 37, 40 ,0.2), rgba(39, 37, 40 ,.44), rgba(39, 37, 40 ,.95)"
        >
            <v-container fluid style="height: 100vh;">
                    <v-row justify="center" align="center" style="height: 100vh;">
                    <v-col align="center" align-self="end">
                        <p class="white--text centuryGothicBold screenTitle">{{ text }}</p>

                        <v-icon x-large color="white" class="bouncingIcon">mdi-chevron-down</v-icon>
                    </v-col>
                </v-row>
            </v-container>
        </v-img>
    </v-container>
</template>

<script>
export default {
    name: "ImageJumbotron_Section",
    props: [
        'imgPath',
        'text'
    ]
}
</script>

<style scoped>
.bouncingIcon {
    margin-top: 37vh;
    animation: bounce 2s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-12px);
    }
}

@media (max-width: 960px) {
    .bouncingIcon {
        margin-top: 32vh !important;
        margin-bottom: 5vh !important;
    }
}
</style>