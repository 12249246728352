<template>
    <v-container fluid class="noSpace pt-8">
        <v-row justify="center" align="center" id="silosSectionRow" class="noSpace">
            <v-col align="center" class="noSpace">
                <v-row justify="center" align="center" class="noSpace">
                    <p class="screenTitle centuryGothicBold" style="color: #e6413d">SILOS MEZCLA BRAVA</p>
                </v-row>

                <v-row justify="center" align="start" class="noSpace">
                    <v-col class="pa-8" cols="12" md="6" lg="6" xl="6" align="center">
                        <v-img
                            src="../../assets/mb/Silo.png"
                        ></v-img>
                    </v-col>
                    <v-col class="pa-8 pt-16"  cols="12" md="6" lg="6" xl="6" align="center">
                        <p class="centuryGothicBold" id="siloDescriptionTitle">
                            Capacidad aproximada de 25 toneladas dependiendo del producto
                        </p>

                        <v-row
                            v-for="(item, index) in detailsList"
                            :key="index"
                            class="noSpace siloDetailRow"
                            align="center"
                            justify="start"
                        >
                            <v-col class="noSpace" cols="3">
                                <v-img
                                    :src="require(`../../assets/icons/` + item.icon)"
                                ></v-img>
                            </v-col>
                            <v-col class="noSpace" cols="9" align="start">
                                <p class="centuryGothic ml-3">{{ item.description }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Silos_Section",
    data() {
        return {
            detailsList: [
                {
                    icon: "Icono Reloj.png",
                    description: "Llenar un silo a su máxima capacidad toma de 45 a 60 minutos."
                },
                {
                    icon: "Icono Tolva.png",
                    description: "Una tolva neumática es la que realiza la carga de producto al silo."
                },
                {
                    icon: "Icono Sacos.png",
                    description: "Un silo lleno, representa evitar el desperdicio de aproximadamente 625 sacos de producto."
                }
            ]
        }
    }
}
</script>

<style scoped>
#silosSectionRow {
    padding: 0 100px !important;
}

#siloDescriptionTitle {
    margin-top: 5vh !important;
    padding: 0 100px !important;
}

.siloDetailRow {
    margin-top: 30px !important;
    padding: 0 50px !important;
}

@media (max-width: 960px) {
    #silosSectionRow {
        padding: 0 10px 0 0 !important;
    }

    #siloDescriptionTitle {
        margin-top: -40px !important;
        padding: 0 40px !important;
    }

    .siloDetailRow {
        margin-top: 20px !important;
        padding: 0 10px !important;
    }
}

@media (min-width: 1900px) {
    #silosSectionRow {
        padding: 0 400px !important;
    }
}
</style>