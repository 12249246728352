<template>
    <v-hover v-slot="{ hover }">
        <v-card
            :elevation="hover ? 8 : 2"
            class="ma-2 machineCard"
        >
            <!-- CARD TITLE -->
            <p class="centuryGothicBold productCardTitle pt-3 text-uppercase" style="color: #e6413d">{{ product.name }}</p>

            <!-- PRODUCT IMAGE -->
            <img
                :src="require('./../../assets/silos/' + product.img)"
                alt=""
                class="productCardImage"
            >

            <v-row justify="center" class="noSpace pt-1 px-3">
                <p class="centuryGothicBold text-uppercase" style="color: #3D3533;">{{ product.subtitle }}</p>
            </v-row>

            <v-row justify="center" class="noSpace px-3 mt-n2 pb-3">
                <p class="centuryGothic detailsText">
                    <span v-for="(item, index) in product.pluginsList" :key="index">
                        + {{ item }} <br>
                    </span>
                </p>
            </v-row>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "SpecializedMachine_Card",
    props: [
        'product'
    ],
}
</script>

<style scoped>
.machineCard {
    height: auto;
}

.productCardTitle {
    text-align: center !important;
    font-size: 20px;
}

.productCardImage {
    width: 100%;
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
}

.detailsText {
    font-size: 0.85em;
}
</style>