<template>
    <v-row
        style="background-color: #e6413d;"
        class="pa-0 ma-0"
        align="center"
        justify="center"
    >
        <v-col class="pa-0 ma-0 mb-9" align="center">
            <!-- SECTION TITLE -->
            <p class="centuryGothicBold screenTitle mt-10 px-4" style="color: #fff !important; text-align: center">
                BENEFICIOS DE UN SILO
            </p>

            <!-- BENEFITS CARDS -->
            <v-row class="pa-0 ma-0" align="center" justify="start" id="cardsRow">
                <!-- CARD -->
                <v-col
                    v-for="(benefit, index) in benefitsList"
                    :key="index"
                    class="cardColumn"
                    sm="12"
                    xs="12"
                    md="6"
                    lg="6"
                    xl="6"
                >
                    <!-- BORDER IMAGE -->
                    <v-img
                        class="cardImage"
                        :src="require(`../../assets/cards/${benefit.img}`)"
                    >
                        <!-- CONTENT -->
                        <p
                            class="centuryGothic white--text cardText"
                        >
                            {{ benefit.description }}
                        </p>
                    </v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "SilosBenefits_Section",
    props: [
        'color'
    ],
    created() {
        this.selectedColorKey = this.$props.color === true ? 'N' : 'R';
    },
    data() {
        return {
            selectedColorKey: '',
            benefitsList: [
                {
                    img: 'Benef 1.png',
                    description: 'del material de condiciones climáticas, así como evitar la ruptura de sacos durante su manejo.',
                },
                {
                    img: 'Benef 2.png',
                    description: 'Permite optimizar el rendimiento del producto al combinarlo con alguna de la maquinaria que complementa el Sistema constructivo.',
                },
                {
                    img: 'Benef 3.png',
                    description: 'el tiempo útil del personal liberándolo de carga y descarga de sacos, así como de limpieza del área.',
                },
                {
                    img: 'Benef 4.png',
                    description: 'Recomendado para el manejo de cantidades considerables de material.',
                },
                {
                    img: 'Benef 5.png',
                    description: 'el manejo de varios productos distintos dentro de una misma obra.',
                },
                {
                    img: 'Benef 6.png',
                    description: 'Espacios de inventarios vs ocupación de altos volúmenes en saco.',
                },
                {
                    img: 'Benef 7.png',
                    description: 'de la obra al tener mayor avance por jornada, optimización de tiempos y recursos, además de mejor rendimiento de producto.',
                },
            ],
        }
    }
}
</script>

<style scoped>
#cardsRow {
    padding: 0 20vh !important;
}

.cardColumn {
    padding: 40px;
}

.cardImage {
    width: 100%;
}

.cardText {
    margin-top: 12.5vh;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 0.95em;
}

@media (max-width: 960px) {
    #cardsRow {
        padding: 0 0 !important;
    }

    .cardColumn {
        margin-bottom: 20px;
        padding: 5px 20px;
    }

    .cardImage {
        width: 100%;
    }

    .cardText {
        margin-top: 10vh;
        font-size: 0.8em !important;
    }
}
</style>